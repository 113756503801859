import 'bootstrap/scss/bootstrap.scss'
import './assets/scss/paper-kit.scss?v=1.3.0'

import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import loadable from '@loadable/component'
import { HelmetProvider } from 'react-helmet-async'
import { CookieConsentWrapper } from './components/CoocieConsentWrapper'

const Index = loadable(() => import('./views/Index'))
const Regulations = loadable(() => import('./views/Regulations'))
const PrivacyPolicy = loadable(() => import('./views/PrivacyPolicy'))
const WorkshopNeeds = loadable(() => import('./views/WorkshopNeeds'))
const FormWorkshopNeedsSignUp = loadable(
  () => import('./views/FormWorkshopNeedsSignUp'),
)
const FormWorkshopRelaxSignUp = loadable(
  () => import('./views/FormWorkshopRelaxSignUp'),
)

export enum Routes {
  Index = '/',
  Regulations = '/regulamin',
  PrivacyPolicy = '/polityka-prywatnosci',
  WorkshopNeeds = '/warsztaty/razem-w-potrzebie',
  FormWorkshopNeedsSignUp = '/zapisy/warsztaty-razem-w-potrzebie',
  FormWorkshopRelaxSignUp = '/zapisy/relaksacja-wsrod-koni',
}

const router = createBrowserRouter([
  {
    path: Routes.Index,
    Component: Index,
  },
  {
    path: Routes.Regulations,
    Component: Regulations,
  },
  {
    path: Routes.PrivacyPolicy,
    Component: PrivacyPolicy,
  },
  {
    path: Routes.WorkshopNeeds,
    Component: WorkshopNeeds,
  },
  {
    path: Routes.FormWorkshopNeedsSignUp,
    Component: FormWorkshopNeedsSignUp,
  },
  {
    path: Routes.FormWorkshopRelaxSignUp,
    Component: FormWorkshopRelaxSignUp,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
      <CookieConsentWrapper />
    </HelmetProvider>
  </React.StrictMode>,
)
